import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Paragraph, Box } from "grommet"

import Layout from "../components/layout"
import Heading from "../components/Heading"
import ResponsiveInnerPad from "../components/ResponsiveInnerPad"
import ShrinkyButton from "../components/ShrinkyButton"
import SEO from "../components/seo"
import SmartLink from "../components/SmartLink"

const ThanksPage = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location =
        "https://www.backdrophome.com/collections/interior-standard"
    }, 5000)
    return () => clearTimeout(timer)
  }, [])

  const { thanks } = useStaticQuery(
    graphql`
      query {
        thanks: file(relativePath: { eq: "backdrop_thanks_header.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 10000, quality: 90) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    `
  )

  return (
    <Layout
      background="white"
      headerImage={thanks.childImageSharp.fluid}
      logoColor="#000"
    >
      <SEO title="Thanks" />
      <ResponsiveInnerPad>
        <Box pad={{ vertical: "medium" }}>
          <Heading level={1} size="large">
            Thanks for applying!
          </Heading>
          <Paragraph margin={{ vertical: "medium" }}>
            We'll be in touch. In the meantime, if you have any questions shoot
            us a note at{" "}
            <SmartLink href="mailto:industry@backdrophome.com">
              industry@backdrophome.com
            </SmartLink>
            .
          </Paragraph>
          <Paragraph>You will be redirected soon...</Paragraph>
          <Paragraph margin={{ vertical: "medium" }}>
            <ShrinkyButton
              href="https://www.backdrophome.com/collections/interior-standard"
              label="Choose your Backdrop"
              primary
            />
          </Paragraph>
        </Box>
      </ResponsiveInnerPad>
    </Layout>
  )
}

export default ThanksPage

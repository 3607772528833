import styled from "styled-components"
import { Button } from "grommet"

const ShrinkyButton = styled(Button)`
  @media (max-width: 411px) {
    font-size: ${p => p.theme.text.small.size};
  }
`

export default ShrinkyButton
